
.A_Form_Container {
      text-align: left;
      padding-top: 50px;
    }
.A_Form_Cont_Paper {
        width: 100%;
     /*   height: 320px; */
}
.A_Form_Div{
    background-color: white;
  /*  width: 300px; */
    height: auto;
    padding-top: 5px;
    margin-bottom: 30px;
}
.AForm_Div_P1 {
    font-size: 15px;
  }

.AForm_HDiv1 {
    height: 30px;
    width: 335px;
    border-bottom: 1px solid var(--gcolor);
    margin-bottom: 5px;
  }
  
  .AForm_Div_H1 {
    font-size: 22px;
    color: var(--gcolor);
  }
  .AForm_PDiv1 {
    margin: 0px 0px;
    margin-top: 4px;
  /*  margin-bottom: 15px;  */
  }
  .TF_div {
    /* height: 50px; */
    margin-top: 10px;
  }
  .TF_Label {
    font-size: 15px;
    font-weight: 400;
  }
.custom-form-input{
  width: 100%;
}
.contact_L{
  text-align: center;
  font-weight: 800;
  font-size: 20px;
}
.A_Form_Div .MuiInput-underline:before {
    border-bottom: 1px solid black !important;
    border-bottom: none !important;
}
.A_Form_Div .MuiInput-underline.Mui-error:after {
    border-bottom: 1px solid rgb(146, 22, 22) !important;
    border-bottom: none !important;
}
.A_Form_Div .MuiInput-underline:after {
    border-bottom: 1px solid black !important;
    border-bottom: none !important;
}
.A_Form_Div .MuiInputBase-input {
    color: #2f2f2f !important;
    padding: 3px 10px !important;
    font-size: 15px !important;
    font-weight:normal !important;
    padding-bottom: 6px !important;
    /* border: 1px solid #d8d8d8; */
   border-radius: 5px;
}
.A_Form_Div .MuiInputBase-root {
  width: 100% !important;
  border-radius: 10px;
  background-color: white;
  margin-top: 5px;
    margin-bottom: 5px;
}
.A_Form_Div .MuiFormLabel-root {
    color: black  !important;
    font-size:15px !important;
    font-weight:600 !important;
}
.A_Form_Div .Button_UL {
  margin-top: 33px;
    width: 100%;
  background-color: cadetblue !important;
  border-bottom: 2px solid white;
  margin-bottom: 5px;
}
.A_Form_Div .MuiButton-containedPrimary {
    font-size: 16px !important;
 /*   width: 120px !important; */
    height: 40px !important; 
    border: 1px solid var(--bcolor) !important;
/*    border-radius: 4px !important; */
    margin-top: 10px !important;
    text-align: center !important;
    align-items: center !important;
    justify-content: center !important;
    cursor: pointer !important;
    color: white !important;
    background-color: black !important;
    min-width: 150px;
}
.A_Form_Div .MuiFormHelperText-root{
    margin-top: 5px !important;
    line-height: 0 !important;
    margin-left: 0px;
    font-size: 11px;
}
.A_Form_Div .MuiFormControl-root{
  width: 100% !important;
}
.A_Form_Div .MuiButton-contained.Mui-disabled {
    font-size: 15px !important;
}
.A_Form_Div .MuiButton-containedPrimary:hover {
    background-color: gray !important;
    color: white !important;
}
.D6_DASAC_CSA_DateDiv {
  margin-top: 9px;
}
.D6_DASAC_CSA_DateDiv .MuiFormLabel-root{
  font-size: 18px !important;
}
.D6_DASAC_CSA_DateDiv .MuiInputBase-root{
  width: 310px !important;
}
.D6_DASAC_CSA_DateDiv .MuiFormControl-marginNormal {
    margin-top: 0px !important;
}
.D6_DASAC_CSA_DateDiv .MuiIconButton-label {
  margin-top: -30px;
  margin-left: -30px;
}
.PNDiv_SA {
    /* height: 55px; */
    margin-top: 10px;
  }

  .PNDiv_SA .PN_Label2 {
      font-size: 15px;
      padding-bottom: 10px;
      font-weight: 400;
  }
  .PNDiv_SA .PN_Input2 {
    height: 20px;
    background-color: rgb(0,0,0,0);
    padding-bottom: 5px;
    margin-top: 5px;

    /* color: #000000; */
  }
  .D6_DASAC_CSA_DateDiv .PN_Input21 {
    /* height: 20px; */
    background-color: rgb(0,0,0,0);
    margin-bottom: 5px;
    /* color: #000000; */
  }
  
  .PNDiv_SA .react-tel-input {
    font-size: 14px !important;
  }
  
  .PNDiv_SA .react-tel-input .form-control {
    margin-top: 10px !important;
    margin-bottom: 0px !important;
    height: 30px !important;
    position: relative;
    letter-spacing: .01rem;
   /* border-radius: 5px !important; */
    border-radius: 5px !important; 
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    width: 100% !important;
    font-size: 15px !important;
    color: black !important;
    background-color:white !important;
    border:1px solid #d8d8d8 !important;
  }
  .PNDiv_SA .react-tel-input .flag-dropdown {
      background-color: white !important;
      border: 1px solid #d8d8d8 !important;
      border-radius: 5px 0 0 5px !important;
      height: 30px;
  }

  
  @media (max-width: 490px) {
    .A_Form_Cont_Paper {
      width: 280px;
    }
    .A_Form_Container {
      width:auto;
    }
    .AForm_HDiv1 {
      width: 255px;
    }
    .AForm_Div_H1 {
      font-size: 19px;
    }
    .D6_DASAC_CSA_DateDiv .MuiInputBase-root{
      width: 255px !important;
    }
    .PNDiv_SA .react-tel-input .form-control {
      width: 89% !important;
    }
    .A_Form_Div .MuiInputBase-root {
      width: 255px !important;
    }
    .A_Form_Div .Button_UL {
      margin-top: 33px;
      width: 255px;
      border-bottom: 1px solid lightgray;
      margin-bottom: 5px;
    }

} 

.react-calendar {
  margin: 8px 0px 35px 0.4px;
  border-radius: 20px;
  box-shadow: 0 4px 32px 0 rgba(204, 204, 204, 0.24);
  font-weight: 500;
  border: 0 !important;
}
.react-date-picker__wrapper{
  border: 1px solid #d8d8d8 !important;
  border-radius: 5px;
}
.react-calendar .react-calendar__viewContainer {
  border-radius: 0 0 20px 20px;
  overflow: hidden;
}

.react-calendar .react-calendar__year-view .react-calendar__tile,
.react-calendar .react-calendar__decade-view .react-calendar__tile,
.react-calendar .react-calendar__century-view .react-calendar__tile {
    color: #000000;
    font-weight: 500;
  }

.react-calendar .react-calendar__navigation button {
    color: #000000;
    font-size: 16px;
    font-weight: 600;
  }

  .react-calendar .react-calendar__decade-view .react-calendar__tile--now, 
  .react-calendar .react-calendar__century-view .react-calendar__tile--now{
    background-color: #36444e;
    color: #fff;
  }
  .react-calendar .react-calendar__decade-view .react-calendar__tile--hasActive, 
  .react-calendar .react-calendar__century-view .react-calendar__tile--hasActive {
    background-color: #36444e;
  }
  .react-calendar .react-calendar__decade-view .react-calendar__tile:enabled:hover, 
  .react-calendar .react-calendar__century-view .react-calendar__tile:enabled:hover,
  .react-calendar .react-calendar__decade-view .react-calendar__tile:enabled:focus, 
  .react-calendar .react-calendar__century-view .react-calendar__tile:enabled:focus {
    background-color: #f0f0f0;
    color: #212b36;
  }

  .react-calendar .react-calendar__year-view .react-calendar__tile--now {
    background-color: #36444e;
    color: #fff;
  }

  .react-calendar .react-calendar__year-view .react-calendar__tile:enabled:hover,
  .react-calendar .react-calendar__year-view .react-calendar__tile:enabled:focus {
      background-color: #f0f0f0;
      color: #212b36;
    }
  
  .react-calendar .react-calendar__year-view .react-calendar__tile--hasActive {
      background-color: #36444e;
      color: #fff;
    }

.react-calendar .react-calendar__month-view .react-calendar__month-view__weekdays__weekday {
      color: #9fa0a4;
    }
    
.react-calendar .react-calendar__month-view .react-calendar__month-view__weekdays__weekday abbr[title] {
      text-decoration: none;
    }

.react-calendar .react-calendar__month-view  .react-calendar__tile.react-calendar__month-view__days__day {
      color: #212b36;
      font-size: 14px;
      font-family: 'Gilroy';
      padding: 5px;
    }
.react-calendar .react-calendar__month-view .react-calendar__tile.react-calendar__month-view__days__day abbr{
  width: 32px;
  height: 32px;
  display: inline-block;
  padding: 8px;
}

.react-calendar .react-calendar__month-view .react-calendar__tile.react-calendar__tile--now.react-calendar__month-view__days__day abbr{
  border-radius: 10px;
  background-color: #f0f0f0;
  color: #212b36;
  width: 32px;
  height: 32px;
  display: inline-block;
  padding: 8px;
  border-radius: 20px;
}

.react-calendar .react-calendar__month-view .react-calendar__tile--active:enabled:hover,
.react-calendar .react-calendar__month-view .react-calendar__tile--active:enabled:focus,
.react-calendar .react-calendar__month-view .react-calendar__tile--now:enabled:hover,
.react-calendar .react-calendar__month-view .react-calendar__tile--now:enabled:focus {
      background-color: transparent;
    }
.react-calendar .react-calendar__month-view .react-calendar__tile--active:enabled:hover abbr,
.react-calendar .react-calendar__month-view .react-calendar__tile--active:enabled:focus abbr,
.react-calendar .react-calendar__month-view .react-calendar__tile--now:enabled:hover abbr,
.react-calendar .react-calendar__month-view .react-calendar__tile--now:enabled:focus abbr{
        background-color: #f0f0f0;
        border-radius: 16px;
    }

.react-calendar .react-calendar__month-view .react-calendar__tile--active {
  background-color: #fff;
}

.react-calendar .react-calendar__month-view .react-calendar__tile--now {
  background-color: #fff;
}
.react-calendar .react-calendar__month-view .react-calendar__tile--active abbr {
      background-color: #36444e;
      color: #fff !important;
      width: 32px;
      height: 32px;
      display: inline-block;
      padding: 8px;
      border-radius: 16px;
}