.Dec6_Industries_B2B_GridS{
    /* margin-top:10px; */
    padding-bottom: 20px;
    justify-content: center;
    
}
/* .Dec6_Industries_B2B_Grid1{
    padding: 20px;
} */
.head_WCU{
    margin-top: 50px !important;
    margin-bottom: 30px;
    font-size: 40px !important;
    border-bottom: solid;
    border-bottom-width: 1px;
    font-weight: 600 !important;
    color: #48a3b8 !important;
}
.sub_WCU{
    padding-top: 25px;
}
.Dec6_Industries_B2B_Cont {
    padding-top: 50px;
}
.card_color{
    background: var(--blueColor);
    color: var(--bcolor);
}
.card_text{
    color: var(--bcolor) !important; 
}
.card_typ{
font-size: 18px !important;
color: #48a3b8 !important;
font-weight: 600 !important;
}

@media (max-width: 500px) {
    .head_WCU{
        margin-top: 50px !important;
        margin-bottom: 30px;
        font-size: 36px !important;
        border-bottom: solid;
        border-bottom-width: 1px;
        font-weight: 600 !important;
        color: #48a3b8 !important;
    }
}