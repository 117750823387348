.heading_Sub{
    font-size: 35px !important;
    font-weight: bolder !important;
}
.img_sub{
    width: 100%;
    height:350px;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position: right;
    background-position: bottom;
}
.heading_A_Sub{
    font-size: 19px !important;
    padding-top: 30px !important;
    font-weight: lighter !important;  
}
.subSection{
    font-size:20px !important;  
}
.blogSub_grid{
    padding-left: 30px;
}
@media (max-width:500px) {
    .img_sub{
height:400px
    }
    .blogSub_grid{
        padding-left: 0px;
    }
}