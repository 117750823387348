.event_bg{
    background-position: center !important;
    background-size: cover !important;
    height: 70vh;
}
.upcoming_head{
    font-size: 32px !important;
    font-weight: 500 !important;
    padding-top: 20px;
    color: rgb(99,192,224);
}
.ppr_card {
    border-radius: 20px !important;
    box-shadow: 1px 2px 40px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 2px 25px 0px rgb(0 0 0 / 12%) !important;
}