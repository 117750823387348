

.D6_SL_Footer_Items {
  height: 65px;
  background-color: #000;
  color: white;
  /* padding-left: 55px; */
  padding-left: 0px;
  padding-top: 15px;
  text-align: center;
    align-items: center;
    border-top:1px solid var(--bcolor);
}
.D6_SL_Footer_Items_NavLink {
    font-size: 14px;
    text-align: center;
    height: 15px;
}

.D6_SL_Footer_Items a {
    text-decoration: none;
    font-size: 14px;
    color: var(--whitecolor);
}

.D6_SL_Footer_Items_P {
    text-align: center;
    font-size: 14px;
    color: white;

}
.D6_SL_Footer_Items_P_Div {
    margin-top: 15px;
}


@media (max-width: 410px) {
   
    .D6_SL_Footer_Items a {
        text-decoration: none;
        font-size: 9px;
        color: var(--whitecolor);
    }
    .D6_SL_Footer_Items_P {
        text-align: center;
        font-size: 14px;
    }
}
