.grid_au{
    /* background: linear-gradient(rgb(99,192,214,0.7),rgb(0,0,0,0.6));  */
    width: 100%;
    height:300px;

width: 100%;

/*   padding-top: 100px;*/
background-repeat: no-repeat;
background-size: cover;
background-position: right;
background-position: bottom;
cursor: pointer;

}
.text_grid_au{
    color: papayawhip;
    font-size: 44px;
    font-weight: 600;
    text-align: center;
    padding-top: 125px;
}
.para_text_grid{
    color: papayawhip;
    padding: 5px;
    font-size: 18px !important;
    padding-top: 100px;
    display: none;
}

.grid_au :hover + .para_text_grid{
    display: block;
    /* background-color: black; */
    background: linear-gradient(rgb(99,192,214,0.7),rgb(0,0,0,0.8));
}

.text_grid_au:hover{
    padding-top: 50px;

}
.text_grid_au:hover + .para_text_grid {
    padding-top: 10px !important;
    /* display: block !important; */
   
}
.card_text1E{
    color: var(--bcolor) !important; 
    font-size: 18px !important;
    font-weight: 600 !important;

}
.card_text2E{
    font-size: 17px !important;
    min-height: 140px;
    /* color: var(--bcolor) !important;  */
}
.card_text2E1{
    font-size: 17px !important;
    /* min-height: 140px; */
    /* color: var(--bcolor) !important;  */
}
.card_typE{
font-size: 26px !important;
color: #48a3b8 !important;
font-weight: 600 !important;
min-height: 50px;
}
/* .text_grid_au.para_text_grid:hover {
    padding-top: 50px;
    display: block;
   
} */