
.D6_SL_SL_Button {
    color: var(--gcolor);
    padding: 5px 20px;
    margin-top: 10px;
    font-size: 22px;
    border: 1px solid var(--ycolor);
    background-color: var(--ycolor);
    cursor: pointer;
    border-radius: 5px;
}

.spinner{
  justify-content: center;
  height: 100vh;
  align-items: center;
}


.count_display{
    /* padding: 26px; */
    max-width: 220px !important;
    color: white;
    height: 200px;
    border-radius: 20px;
    font-size: 24px !important;
    text-align: center;
    background: linear-gradient(rgb(99,192,224,0.9),rgb(0,0,0,0.3));
    background-color: deepskyblue;
    padding-top: 50px;
    margin: 10px;
    font-weight: 900;
}
.count_name{
  padding-top: 50px;
}
.count_container{
  margin-top: -101px;
  justify-content: center;
}
  .D6_SL_SC_Slider_Div1 {
    height: 60vh;
    /* background: linear-gradient(rgb(99,192,224,0.7),rgb(0,0,0,0.5)), url('../../../images/Filly4.jpg');   */
    ;
    padding-top: 232px !important;
  }
  /* .D6_SL_Img1_div{
    margin-top: 50px;
  } */
  .img{
    width:100%
  }
  
  
.heading1{
  padding-top: 200px;
  color: white;
  font-size: 75px;
  font-weight: bolder;
  padding-left: 20px;
}
.heading2{
  color: white;
  font-size: 40px;
  font-weight: normal;
  padding-top: 5vh;
 

    padding-left: 20px;
    text-align: center;
}
.heading3{
  padding-top: 40px;
  color: #ffffff;
  font-size: 35px;
  font-weight: normal;
  padding-left: 20px;
}




@media (max-width: 600px) {
    
 
  
    .D6_SL_SL_Button {
        padding: 5px 5px;
        font-size: 16px;
    }
   
      .icon-close-btn{
       left: 305px !important;
      }
      
      .heading1{
        font-size: 35px !important;
      }
      .heading2{
        font-size: 25px !important;
      }
      .heading3{
        font-size: 23px !important;
      }

}
@media (max-width: 500px) {
    .D6_SC_SliderDiv {
        bottom: 40px;
    }
    .heading2{
      /* color: white; */
      font-size: 24px !important;
      font-weight: normal;
      padding-top: 10vh;
     
    
        padding-left: 20px;
        text-align: center;
    }
    .count_display{
      /* padding: 26px; */
      max-width: 320px !important;
      min-width: 255px;
      color: white;
      height: 200px;
      border-radius: 20px;
      font-size: 20px !important;
      text-align: center;
      background: linear-gradient(rgb(99,192,224,0.9),rgb(0,0,0,0.3));
      background-color: deepskyblue;
      /* background: linear-gradient(rgb(0,0,0,0.4),rgb(0,0,0,0.6)); */
      /* background: linear-gradient(rgb(99,192,224,0.4),rgb(99,192,224,0.6)); */

      /* background-color: #52b9d1; */
      padding-top: 50px;
      margin: 10px;
      font-weight: 900;
  }
}
