.background_press{
    background-position: center !important;
    background-size: cover !important;
    height: 60vh;
}
.ppr_press{
    min-height: 150px;
    margin-top: -10vh;
    width: 100%;
    text-align: center !important;
}
.img_press{
border: 2px solid rgba(151,151,151,.25);
padding: 10px;
border-radius: 10px;
width: 55%;
}
.card_press{
    min-height: 250px !important;
    margin: 10px;
}
.card_press .MuiCardMedia-root{
    object-fit: contain !important;
    border-bottom: 1px solid rgba(151,151,151,.25) !important;
}
.card_headP{
    font-size: 20px !important;
    font-weight: 600 !important;
    min-height: 50px;
    text-align: justify;
}
.card_textP{
    font-size: 17px !important;
    font-weight: normal !important;
    min-height: 200px;
    text-align: justify;
    padding-top: 20px;
    padding-bottom: 10px;
}