.Div1 {
    /* margin-top: 10px; */
 /*   margin-left: 500px; */
    /* margin: 20px 500px; */
    color: #52B9D1;
    font-family: var(--fontfamily);
    /* background-color: coral; */
    display: flex;
    margin-top: 200px;
    justify-content: center;
}