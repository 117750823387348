


.D6_SL_SC_Slider_Div{
    /* background: linear-gradient(rgb(0,0,0,0.7),rgb(0,0,0,0.5)), url("../../../images/success.jpg"); */
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right;
    background-position: bottom;    
}
.D6_SL_SC_Slider_Div .slick-next {
    right: 0px !important;
}
.D6_SL_SC_Slider_Div .slick-slide {
    /* min-height: 350px !important; */
}
.D6_SL_SC_Slider_Div .slick-next:before {
    content: '' !important;
}
.D6_SL_SC_Slider_Div .slick-dots {
    bottom: 0px !important;
}
.D6_SL_SC_Slider_Div .slick-dots li{
    width:50px;
}
.D6_SL_SL_Button {
    color: var(--gcolor);
    padding: 5px 20px;
    margin-top: 10px;
    font-size: 22px;
    border: 1px solid var(--ycolor);
    background-color: var(--ycolor);
    cursor: pointer;
    border-radius: 5px;
}
.D6_TS_GDiv_Img{
    width: 125px;
    height: 125px;
    margin: 0 auto;
    border-radius: 50%;
    /* border: 2px dotted rgb(99,192,214); */
    overflow: hidden;
    padding:5px;
}
.D6_TS_GDiv_ImgS{
    width: 100%;
    height: 100%;
    border-radius: 50%
}
.D6_SL_SC_Slider_Div .slick-dots li button:before {
    width: 50px !important;
    height: 5px !important;
    color: white !important;
    background:rgb(99,192,214) !important;
    content: '' !important;
    border-radius: 5px;
}
.D6_SC_SliderDiv {
    width: 100% !important;
    height: auto;
}
.D6_SC_SliderDivH1 {
    font-size: 41px;
   color: rgb(99,192,214);
       padding-bottom: 10px;
    text-align: center;
}
.D6_SC_SliderDivH2 {
    font-size: 21px;
    font-weight: normal;
    color: white;
}
.D6_SL_SC_Slider_Div .prevArrow {
    position: absolute;
    top: 40%;
    left: 20px;
    z-index: 100;
    cursor: pointer;
    font-size: 42px;
    color: white;
  }
  
  .D6_SL_SC_Slider_Div .nextArrow {
    position: absolute;
    top: 40%;
    right: 20px;
    z-index: 100;
    cursor: pointer;
    font-size: 42px;
    color: white;
  }
  .img1{
      width: 100%;
      height: 500px;
  }
  .testi_text{

    text-align: center;
    font-size: 16px;
    padding: 20px;
    color: white;
  }
  .testi_text1{

    text-align: center;
    font-size: 18px;
    color: rgb(99,192,214);
    font-weight: 800;
  }
  .testi_text2{

    text-align: center;
    font-size: 17px;
    color: white;
    font-weight: 700;
    padding: 20px !important;
  }


@media (max-width: 600px) {
   
    .D6_SC_SliderDiv {
        /* margin-left:20px; */
        /* bottom: 85px; */
    }
    .D6_SC_SliderDivH1 {
        font-size: 24px;
        margin-top:20px;
    }
    .D6_SC_SliderDivH2 {
        font-size: 16px;
        font-weight: normal;
        color: white;
    }
    .D6_SL_SL_Button {
        padding: 5px 5px;
        font-size: 16px;
    }
    .D6_SL_SC_Slider_Div .prevArrow {
        display:block;
        left: 0px !important;
      }
      .D6_SL_SC_Slider_Div .nextArrow {
        display:block;
        right: 0px !important;

      }
}
@media (max-width: 500px) {
    .D6_SC_SliderDiv {
        bottom: 65px;
    }
}
