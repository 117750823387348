
.SignIn_Form_Div {
    font-family: var(--fontfamily);
    width: 250px !important;
}
.D6_SIForm_TV_Div {
    margin-bottom: 5px;
}

.SignIn_Form_Div .MuiInputBase-input{
    color: black !important;
    width: 250px !important;
    font-weight: 300px !important;
}
.SignIn_Form_Div .MuiInputBase-root {
    color: #7f7f7f !important;
    border: 1px  #d8d8d8 !important;
}
.SignIn_Form_Div .MuiInput-underline:after {
    color: #7f7f7f !important;
    border-bottom: 1px solid #52B9D1 !important;
}
.SignIn_Form_Div .MuiFormLabel-root {
    color: black !important;
}
.D6_SIForm_Label {
    font-size: 1 rem;
    color: #151616;
    font-weight: 300;
}
.D6_SIForm_error {
    font-size: 14px;
    color: #151616;
    font-weight: 300;
    width: 100%;
    text-align: center;
    display: block;
}
.D6_SIForm_errorf {
    font-size: 14px;
    color: #151616;
    font-weight: 300;
    width: 100%;
    text-align: center;
    display: block;
    border: 1px solid  #151616;
}
.SignIn_Form_Div a{
    /* color: black; */
    cursor: pointer;
}
.D6_OTPPage_link{
    width: 100%;
    text-align: center;
    display: block;
    margin-top: 20px;
    margin-bottom: 10px;
    color: #61126b;
    /* text-decoration: underline !important; */
}
.D6_OTPPage_link:hover{
    text-decoration: none !important;
}