.dash_sub{
    background-color: #52B9D1 !important;
        height: 200px;
    margin: 20px;
    color: white !important;
    text-align: center;
    font-size: 24px;
    width: 200px;
    font-weight: 800;
    padding-top: 95px;
    cursor: pointer;
  }
  .appbarMain{
      top:100px !important;
      background-color: #151616 !important;
  }
.D6_TS_GDiv_ImgS_Admin{
  width: 100%;
    height: 100%;
    border-radius: 50%;
    border-color: #52B9D1;
    border-style: solid;
}
.mainContent{
  padding: 0.5rem 2rem !important;
  font-size: 1.1rem !important;
  font-weight: 500 !important;
}
.img_grid{
  padding-left: 20px;
}
.form_switch{
  place-items: end;
}
.completed{
  font-size: 1.3rem !important;
  color: green;
  /* padding: 5px; */

}
  @media (max-width: 1200px) {
    .D6_FE_DD {
        height: 150px ;
    }
}
  @media (max-width: 410px) {
    .appbarMain{
        top:175px !important
    }
    .img_grid{
      padding-left: 0px;
    }
    .gird_chip{
      text-align: -webkit-center;
      padding-top: 10px;
    }
    .dash_sub{
            height: 150px;
        margin: 10px;
     
        width: auto !important;
        padding-top: 65px;
      }
      .form_switch{
        place-items: start;
      }
    }